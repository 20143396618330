import React, { useState, useMemo, useEffect } from "react";
import * as Yup from "yup";
import { Link, FormattedMessage, useIntl } from "gatsby-plugin-intl";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Transition } from "@tailwindui/react";
// import tw from "twin.macro";
import { ApolloProvider } from "@apollo/client";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { client } from "../gatsby-theme-apollo/client";
import { useContactUsMutation } from "../components/app/generated/graphql";

const ContactUsPage: React.FC = () => {
  const lang = useIntl();

  const ValidationSchema = useMemo(() => {
    return Yup.object().shape({
      email: Yup.string()
        .email(lang.formatMessage({ id: "error_invalid_email" }))
        .required(lang.formatMessage({ id: "required" })),
      message: Yup.string().required(lang.formatMessage({ id: "required" })),
      reason: Yup.string().required(lang.formatMessage({ id: "required" })),
    });
  }, [lang]);

  const [contactUs, { data, loading, error }] = useContactUsMutation();
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  return (
    <ApolloProvider client={client}>
      <Layout>
        <SEO
          title="Contact Us"
          description="Please contact us with any questions or inquries and we will answer them as soon as we can. If you are contacting us about registering your organization please add your phone number and organization's legal name to the message. If you would like to invite an organization please add a contact email and/or email of that organization to the message."
        />
        <Transition
          show={showSuccess}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div>
            <div className="rounded-md bg-green-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg
                    className="h-5 w-5 text-green-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div className="ml-3">
                  <p className="text-sm leading-5 font-medium text-green-800">
                    <FormattedMessage id="sent_msg" />
                  </p>
                </div>
                <div className="ml-auto pl-3">
                  <div className="-mx-1.5 -my-1.5">
                    <button
                      type="button"
                      onClick={() => setShowSuccess(false)}
                      className="inline-flex rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:bg-green-100 transition ease-in-out duration-150"
                      aria-label="Dismiss"
                    >
                      <svg
                        className="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Transition>
        <Transition
          show={showErrorMessage}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div>
            <div className="rounded-md bg-red-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                  </svg>
                </div>
                <div className="ml-3">
                  <h3 className="text-sm leading-5 font-medium text-red-800">
                    <FormattedMessage id="oops" />
                  </h3>
                  <div className="mt-2 text-sm leading-5 text-red-700">
                    <ul className="list-disc pl-5">
                      <li>
                        {errorMessage}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="ml-auto pl-3">
                  <div className="-mx-1.5 -my-1.5">
                    <button
                      type="button"
                      onClick={() => setShowErrorMessage(false)}
                      className="inline-flex rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:bg-red-100 transition ease-in-out duration-150"
                      aria-label="Dismiss"
                    >
                      <svg
                        className="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Transition>
        <div className="max-w-2xl mx-auto py-16 sm:py-24 sm:px-6 lg:max-w-screen-xl lg:px-8">
          <div>
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <div className="px-4 sm:px-0">
                  <h2 className="text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10 capitalize">
                    <FormattedMessage id="nav_link_contact_us" />
                  </h2>
                  <p className="mt-1 text-sm leading-5 text-gray-600">
                    <FormattedMessage id="contact_us_1" />
                  </p>
                </div>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-2">
                <Formik
                  initialValues={{
                    email: "",
                    message: "",
                    reason: "General Question or Inquery",
                  }}
                  validationSchema={ValidationSchema}
                  onSubmit={async (values, actions) => {
                    try {
                      const res = await contactUs({
                        variables: {
                          ...values,
                          clientId: process.env.GIVFAST_API_CLIENT_ID,
                        },
                      });

                      if (
                        res.data &&
                        res.data.contactUs &&
                        res.data.contactUs.success
                      ) {
                        setShowSuccess(true);
                        actions.resetForm();
                      } else {
                        setErrorMessage(lang.formatMessage({ id: "oops" }));
                        setShowErrorMessage(true);
                      }
                    } catch (error) {
                      console.error(error);
                      setErrorMessage(error);
                      setShowErrorMessage(true);
                    }
                    actions.setSubmitting(false);
                  }}
                >
                  {({ isSubmitting, errors }) => (
                    <Form>
                      <div className="shadow sm:rounded-md sm:overflow-hidden">
                        <div className="px-4 py-5 bg-white sm:p-6">
                          <div className="grid grid-cols-3 gap-6">
                            <div className="col-span-3 sm:col-span-2">
                              <label
                                htmlFor="email"
                                className="block text-sm font-medium leading-5 text-gray-700"
                              >
                                <FormattedMessage id="label_your" />{` `}
                                <FormattedMessage id="label_email" />
                              </label>
                              <div className="mt-1 rounded-md shadow-sm">
                                <Field
                                  type="email"
                                  name="email"
                                  className={`form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${
                                    errors.email
                                      ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                                      : ""
                                  }`}
                                  placeholder="you@example.com"
                                />
                              </div>
                              <ErrorMessage name="email">
                                {(msg: string): React.ReactNode => (
                                  <p
                                    className="mt-2 text-sm text-red-600"
                                    id="email-error"
                                  >
                                    {msg}
                                  </p>
                                )}
                              </ErrorMessage>
                            </div>
                            <div className="col-span-3 sm:col-span-2">
                              <label
                                htmlFor="reason"
                                className="block text-sm font-medium leading-5 text-gray-700"
                              >
                                <FormattedMessage id="label_reason" />
                              </label>
                              <Field
                                name="reason"
                                as="select"
                                className="mt-1 block form-select w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                              >
                                <option value="General Question or Inquery">
                                  {lang.formatMessage({ id: "reason_1" })}
                                </option>
                                <option value="Register an organization">
                                  {lang.formatMessage({ id: "reason_2" })}
                                </option>
                                <option value="Invite an organization">
                                  {lang.formatMessage({ id: "reason_3" })}
                                </option>
                                <option value="Other">
                                  {lang.formatMessage({ id: "reason_4" })}
                                </option>
                              </Field>
                            </div>
                          </div>

                          <div className="mt-6">
                            <label
                              htmlFor="message"
                              className="block text-sm leading-5 font-medium text-gray-700"
                            >
                              <FormattedMessage id="label_message" />
                            </label>
                            <div className="rounded-md shadow-sm">
                              <Field
                                as="textarea"
                                name="message"
                                className={`form-textarea mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${
                                  errors.message
                                    ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                                    : ""
                                }`}
                                placeholder="description"
                                rows={3}
                              />
                            </div>
                            <ErrorMessage name="message">
                              {(msg: string): React.ReactNode => (
                                <p
                                  className="mt-2 text-sm text-red-600"
                                  id="message-error"
                                >
                                  {msg}
                                </p>
                              )}
                            </ErrorMessage>
                          </div>
                        </div>
                        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                          <span className="block rounded-md shadow-sm">
                            <button
                              type="submit"
                              className="relative block w-full justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-teal-600 hover:bg-teal-500 focus:outline-none focus:border-teal-700 focus:shadow-outline-teal active:bg-teal-700 transition duration-150 ease-in-out capitalize"
                              disabled={loading || isSubmitting}
                            >
                              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                {(loading || isSubmitting) && !error && (
                                  <svg
                                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <circle
                                      className="opacity-25"
                                      cx="12"
                                      cy="12"
                                      r="10"
                                      stroke="currentColor"
                                      strokeWidth="4"
                                    ></circle>
                                    <path
                                      className="opacity-75"
                                      fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                  </svg>
                                )}
                              </span>
                              <FormattedMessage id="submit" />
                            </button>
                          </span>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </ApolloProvider>
  );
};

export default ContactUsPage;
